import { CornerRightUp } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { projects } from "../projects";
import Container from "./Container";

export default function Projects() {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState(projects);

  let filters = [
    "Full Stack",
    "Backend",
    "Frontend",
    "Web Development",
    "UI/UX",
  ];

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
  };

  useEffect(() => {
    const filterItems = () => {
      if (selectedFilters.length > 0) {
        const tempItems = projects.filter((item) =>
          selectedFilters.some((filter) => item.category.includes(filter))
        );
        setFilteredItems(tempItems);
      } else {
        setFilteredItems([...projects]);
      }
    };

    filterItems();
  }, [selectedFilters]);

  return (
    <section className="py-10 sm:py-16">
      <Container className="flex flex-col items-center gap-10">
        <div className="flex justify-center flex-wrap gap-2 sm:gap-4 pt-2">
          {filters.map((category, i) => (
            <button
              onClick={() => handleFilterButtonClick(category)}
              className={`px-4 py-1 sm:px-8 sm:py-2 bg-[#2A2A2A] text-sm rounded-lg border border-[#606060] hover:bg-[#3A3A3A] hover:border-text_secondary hover:shadow-[0_0_5px_rgba(0,0,0,0.1)] transition-all duration-300 ${
                selectedFilters?.includes(category)
                  ? "bg-[#3A3A3A] border-[#B4B4B4] shadow-[0_0_5px_rgba(0,0,0,0.1)] shadow-[#B4B4B4]"
                  : ""
              }`}
              key={i}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="grid md:grid-cols-2 gap-6 w-full">
          {filteredItems.map((project, i) => (
            <div key={i} className="flex flex-col gap-4">
              <Link to={project.path} className="group">
                {project.image ? (
                  <div
                    className="w-full h-[200px] sm:h-[300px] border border-[#484848] flex justify-end p-4 rounded-xl hover:border-text_secondary transition-all duration-300"
                    style={{
                      backgroundImage: `url(${project.image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="bg-[#2a2a2a] rounded-lg border border-[#606060] w-[40px] h-[40px] items-center justify-center opacity-0 group-hover:opacity-100 flex hover:bg-[#3A3A3A] hover:border-text_secondary transition-all duration-300">
                      <CornerRightUp size={16} />
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-[300px] bg-bg_secondary border border-[#484848] flex justify-end p-4 rounded-xl hover:border-text_secondary transition-all duration-300">
                    <div className="bg-[#2a2a2a] rounded-lg border border-[#606060] w-[40px] h-[40px] items-center justify-center opacity-0 group-hover:opacity-100 flex hover:bg-[#3A3A3A] hover:border-text_secondary transition-all duration-300">
                      <CornerRightUp size={16} />
                    </div>
                  </div>
                )}
              </Link>
              <div>
                {project.logo ? (
                  <b className="flex items-center gap-2">
                    <img
                      src={project.logo}
                      alt={project.name}
                      className="w-8"
                    />
                    {project.name}
                  </b>
                ) : (
                  <b>{project.name}</b>
                )}
                <p className="text-sm mt-2 text-text_secondary">
                  {project.description}
                </p>
                <div className="flex gap-2 mt-2">
                  {project.category.map((category, i) => (
                    <p
                      className="text-xs text-text_secondary px-2 bg-[#2a2a2a] rounded-full"
                      key={i}
                    >
                      {category}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
