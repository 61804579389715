import React from "react";
import Container from "../components/Container";
import { ReactComponent as NameSVG } from "../assets/name.svg";
import { Github, Linkedin } from "lucide-react";

export default function Footer() {
  return (
    <section className="py-16">
      <Container>
        <div className="md:pb-6 border-b border-text_secondary mb-4 sm:mb-6">
          <NameSVG className="max-w-full block" />
        </div>
        <div className="flex justify-between">
          <h3 className="text-sm text-text_secondary">
            Made by{" "}
            <a
              href="https://www.linkedin.com/in/owenkanzler/"
              aria-label="Owen Kanzler"
              className="text-text font-bold hover:text-text_secondary transition-all duration-300"
            >
              Owen Kanzler
            </a>
          </h3>
          <div className="flex items-center gap-4 text-sm">
            <a
              href="./resume.pdf"
              aria-label="Resume"
              className="hover:text-text_secondary transition-all duration-300"
            >
              Resume
            </a>
            <a
              href="https://www.linkedin.com/in/owenkanzler/"
              aria-label="LinkedIn"
              className="hover:text-text_secondary transition-all duration-300"
            >
              <Linkedin size={16} />
            </a>
            <a
              href="https://github.com/owenkanzler"
              aria-label="Github"
              className="hover:text-text_secondary transition-all duration-300"
            >
              <Github size={16} />
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
}
