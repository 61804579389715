import tweeter from "./assets/tweeter.svg";
import omnistudy from "./assets/omnistudy.svg";
import stride from "./assets/stride.svg";
import joco from "./assets/joco.svg";
import obg from "./assets/obg.svg";
import tbg from "./assets/tbg.svg";
import ibg from "./assets/ibg.svg";
import bbg from "./assets/bbg.svg";
import jbg from "./assets/jbg.svg";
import nbg from "./assets/nbg.svg";
import sbg from "./assets/sbg.svg";
import smock from "./assets/smock.svg";
import smock2 from "./assets/smock2.svg";
import oMock from "./assets/oMock.svg";
import oMock2 from "./assets/oMock2.svg";
import tMock from "./assets/tMock.svg";
import bMock from "./assets/bMock.svg";

export const projects = [
  {
    name: "Stride",
    description: "Stride is an all-encompassing fitness application.",
    fullDescription:
      "Stride is an all-encompassing fitness application that empowers users to share, discuss, and educate themselves on various aspects of fitness. The platform offers a seamless experience for users to log in or sign up, allowing them to create posts, comment on content, express appreciation through likes, and follow other users.",
    responsibilies:
      "I developed the entire fullstack app using React, Node.js, Express, and MongoDB. I also designed the UI/UX of the app using TailwindCSS and AWS S3 for image storage.",
    category: ["Full Stack", "Frontend", "Backend", "UI/UX"],
    techStack: [
      "React",
      "Node.js",
      "Express",
      "NoSQL",
      "MongoDB",
      "AWS S3",
      "TailwindCSS",
    ],
    logo: stride,
    image: sbg,
    images: [smock, smock2],
    path: "/stride",
    links: [
      {
        name: "Live App",
        url: "https://stride-app-a0afeefab00a.herokuapp.com/",
      },
    ],
  },
  {
    name: "Omnistudy",
    description:
      "AI educational app to help students achieve their academic goals.",
    fullDescription:
      "OmniStudy, meaning 'Study for all' with the Latin prefix 'omni' and the suffix 'study,' is a university-based artificial intelligence educational platform. It enables students to organize their work efficiently into foundational tasks such as courses, assignments, and lectures. Using AI, the platform allows students to upload personal documents for interaction through question answering, summarization, and question generation, helping them save time on coursework while gaining a solid understanding of the material. OmniStudy participated in the Google Developer Student Clubs 2024 Solution Challenge, targeting the United Nations goals for Quality Education and Reduced Inequalities, and placed in the top 100 submissions globally.",
    responsibilies:
      "My main responsibilities for OmniStudy: include leading the frontend development of the app, writing the majority of the frontend code, connecting the app to the backend, and serving as the UI/UX designer.",
    category: ["Full Stack", "Frontend", "UI/UX"],
    techStack: [
      "React",
      "TypeScript",
      "Node.js",
      "Express",
      "MySQL",
      "Python",
      "Docker",
      "AWS",
      "GSP",
      "TailwindCSS",
    ],
    logo: omnistudy,
    image: obg,
    images: [oMock, oMock2],
    path: "/omistudy",
    links: [
      {
        name: "Demo Video",
        url: "https://www.youtube.com/watch?v=IHGnI6pRZzA",
      },
    ],
  },
  {
    name: "Tweeter",
    description:
      "Real time messaging app that allows users to engage in conversations with each other based on the random question that gets generated.",
    fullDescription:
      "Tweeter is a real time messaging app that allows users to engage in conversations with each other based on the random question that gets generated. By incorporating real-time messaging functionality, users can have seamless conversations with each other, fostering a sense of community and collaboration.",
    responsibilies:
      "My main responsibilities for Tweeter: include setting up all of the JWT authentication, connecting, styling, and creating the frontend using React, and setting up all of the mutations, resolvers, and typeDefs for our GraphQL express server.",
    category: ["Full Stack", "Frontend", "Backend", "UI/UX"],
    techStack: [
      "React",
      "Node.js",
      "Express",
      "MongoDb",
      "Mongoose",
      "GraphQL",
      "JWT",
      "Socket.io",
      "JavaScript",
      "TailwindCSS",
    ],
    logo: tweeter,
    image: tbg,
    images: [tMock],
    path: "/tweeter",
    links: [
      {
        name: "Github",
        url: "https://github.com/owenkanzler/tweeter",
      },
      {
        name: "Live App",
        url: "https://tweeter-4z96.onrender.com/",
      },
    ],
  },
  {
    name: "Icebreakers",
    description:
      "Social media app for users to answer funny Icebreaker type questions and view others answers.",
    fullDescription:
      "Icebreaker is a custom social media app that prompts you funny icebreaker questions that the 	user can answer. Once answered they’ll be able to view other users' answers and comment on them.",
    responsibilies:
      "My main responsibilities for Icebreakers: include handling all of the express.js creating our custom REST API, I wrote all of the client side javascript so that the app functions correctly, and I assisted with the postgres/sequelize models.",
    category: ["Full Stack", "Backend"],
    techStack: [
      "Javascript",
      "Express.js",
      "PostgreSQL",
      "Sequelize",
      "Bcrypt",
      "Handlebars.js",
      "mySQL",
    ],
    image: ibg,
    path: "/icebreakers",
    links: [
      {
        name: "Github",
        url: "https://github.com/owenkanzler/Icebreakers",
      },
      {
        name: "Live App",
        url: "https://icebreaker-2kco.onrender.com/login",
      },
    ],
  },
  {
    name: "Boredom",
    description:
      "App that gives you ideas and images of the idea for when you are bored.",
    fullDescription:
      "Our website helps you beat boredom by suggesting random activities with images at the click of a button. Save or trash activities as you like! Through this project, we improved our teamwork, communication, and learned how to integrate multiple APIs for a seamless user experience.",
    responsibilies:
      "My main responsibilities for Boredom: include leading the frontend development of the app, writing all the javascript for the app, and I also served as the UI/UX design.",
    category: ["Frontend", "UI/UX"],
    techStack: ["HTML", "JavaScript", "CSS", "TailwindCSS"],
    image: bbg,
    images: [bMock],
    path: "/boredom",
    links: [
      {
        name: "Github",
        url: "https://github.com/owenkanzler/boredom-app",
      },
      {
        name: "Live App",
        url: "https://owenkanzler.github.io/boredom-app/",
      },
    ],
  },
  {
    name: "JOCO",
    description: "Website made for a house detailing company.",
    category: ["Web Development", "UI/UX"],
    logo: joco,
    image: jbg,
    path: "https://joco-detailing.com/",
  },
  {
    name: "Neighbors Landscaping",
    description: "Website made for a landscaping company.",
    category: ["Web Development", "UI/UX"],
    image: nbg,
    path: "https://neighborsmn.com/",
  },
];
