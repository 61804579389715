import React from "react";
import Container from "./Container";

export default function Hero() {
  return (
    <section className="pt-[10rem] sm:pt-[14rem] pb-10 sm:pb-16">
      <Container className="flex flex-col items-center ">
        <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold tracking-tight text-center text-text_secondary">
          I'm <span className="text-text">Owen Kanzler</span>
          <br />A Full Stack Developer
        </h1>
        <p className="text-text_secondary text-center mt-4 sm:mt-6 max-w-xl md:max-w-3xl">
          Full stack developer and designer based in Minneapolis, crafting
          seamless web experiences with modern technologies.
        </p>
      </Container>
    </section>
  );
}
