import React from "react";
import Container from "./Container";

export default function About() {
  return (
    <section className="py-10 sm:py-16">
      <Container>
        <div className="grid md:grid-cols-2 gap-0 sm:gap-6">
          <h2 className="text-xl sm:text-3xl md:text-5xl font-bold tracking-tight text-text_secondary">
            A Little Bit <span className="text-text">About Me</span>
          </h2>
          <div className="flex flex-col gap-8 py-4">
            <div className="text-text_secondary flex flex-col gap-2">
              <p>
                Software engineer filled with passion and ambition to create
                clean and user friendly code. I take pride in solving complex
                real world problems with innovative solutions. Using my
                expertise in the MERN stack, I have created a record of high
                quality apps that exceed my clients expectations.
              </p>
              <p>
                My toolbox of skills include Javascript, React, Next.js, Java,
                Python, MongoDB, Node.js, Express, Handlebar.js, MySQL, and
                Firebase. With my passion for software development I am
                constantly keeping up with industry best practices and using
                agile methodologies to succeed in such a competitive field.
              </p>
            </div>
            <div>
              <a
                href="mailto:oj.kanz1@gmail.com"
                className="px-8 py-2 text-sm bg-text text-[#111] rounded-lg border border-[#484848] hover:bg-[#3A3A3A] hover:border-text_secondary hover:text-text duration-300"
              >
                Contact Me
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
